import React from "react";
import { useRouter } from "next/router";
import classNames from "classnames";

import Link from "../components/Link";
import StoryblokImage from "../components/StoryblokImage";

type Props = {
  background?: any;
  flip: boolean;
  no_border: boolean;
  source?: any;
  text?: any;
  text_color?: string;
};

export default function JournalBlock({
  background,
  flip,
  no_border,
  source: story,
  text,
  text_color,
}: Props): React.ReactElement {
  const router = useRouter();

  const date = new Date(
    story.first_published_at || story.created_at
  ).toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
    day: "2-digit",
  });

  const excerpt = story.content?.excerpt?.content + "<h4>– READ MORE –</h4>";

  const blockContent = text.content;

  const textHtml = (
    <>
      <div />

      {blockContent ? (
        <div
          className="Journal-content u-richText"
          dangerouslySetInnerHTML={{
            __html: blockContent,
          }}
        />
      ) : (
        <div className="Journal-content">
          <h4>{date}</h4>
          <h2>{story.name}</h2>
        </div>
      )}

      <div
        className="Journal-excerpt"
        dangerouslySetInnerHTML={{
          __html: excerpt,
        }}
      />
    </>
  );

  let mediaComponent;

  if (background?.filename) {
    const fileExt = background.filename.split(".").pop().toLowerCase();

    if (fileExt === "mp4") {
      mediaComponent = (
        <video playsInline autoPlay loop muted src={background.filename} />
      );
    } else {
      mediaComponent = <StoryblokImage fill asset={background} />;
    }
  } else if (story?.content?.featured_image?.filename) {
    mediaComponent = (
      <StoryblokImage fill asset={story.content.featured_image} mobile={story.content.featured_mobile} />
    );
  }

  const classes = classNames("Block Blocks--1 Block-journal Block-darkColor", {
    "no-border": no_border,
    flip: flip,
  });

  const content = (
    <>
      <div className="Block-image">{mediaComponent}</div>
      <div className="Block-content">{textHtml}</div>
    </>
  );

  if (story && !router.query._storyblok) {
    return (
      <section className="Blocks Blocks--1">
        <Link className={classes} href={`/${story.full_slug}`}>
          {content}
        </Link>
      </section>
    );
  }

  return (
    <section className="Blocks Blocks--1">
      <div className={classes}>{content}</div>
    </section>
  );
}
