import React, { useContext } from "react";

import { getAllStoryblokStories } from "../lib/storyblok-api";
import Link from "../components/Link";
import StoryblokImage from "../components/StoryblokImage";
import { StoryContext } from "../pages/[...route]";
import { BlockQuery } from "./DynamicBlock";

type Props = {
  _uid: string;
};

export default function JournalBlocks({ _uid }: Props): React.ReactElement {
  const context = useContext(StoryContext);
  const queryData = context.queries[_uid];
  const stories = queryData.stories;

  return (
    <section className="Blocks Blocks--journal Blocks--bordered">
      {stories.map((story) => {
        const date = new Date(
          story.first_published_at || story.created_at
        ).toLocaleDateString("en-UK", {
          month: "short",
          year: "numeric",
          day: "2-digit",
        });

        const featuredImage = story.content?.featured_image;
        const featuredMobile = story.content?.featured_mobile;
        const excerpt = story.content?.excerpt?.content;

        let mediaComponent;

        if (featuredImage?.filename) {
          const fileExt = featuredImage.filename.split(".").pop().toLowerCase();

          if (fileExt === "mp4") {
            mediaComponent = (
              <video
                playsInline
                autoPlay
                loop
                muted
                src={featuredImage.filename}
              />
            );
          } else {
            mediaComponent = (
              <StoryblokImage
                fill
                asset={featuredImage}
                mobile={featuredMobile}
                sizes="(min-width: 768px) 50vw, (min-width: 1024px) 25vw, 100vw"
              />
            );
          }
        } else if (story?.content?.featured_image?.filename) {
          mediaComponent = (
            <StoryblokImage
              fill
              asset={story.content.featured_image}
              mobile={story.content.featured_mobile}
              sizes="(min-width: 768px) 50vw, (min-width: 1024px) 25vw, 100vw"
            />
          );
        }

        return (
          <Link
            className="Block Block-portrait Block-lightColor Block-cardHover"
            href={"/" + story.full_slug}
            key={story.id}
          >
            {mediaComponent && (
              <div className="Block-image">{mediaComponent}</div>
            )}

            <div className="Block-content">
              <div className="Block-text u-richText">
                <h5>{date}</h5>
                <h3>{story.name}</h3>
              </div>

              {excerpt && (
                <div
                  className="Block-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: excerpt,
                  }}
                />
              )}
            </div>
          </Link>
        );
      })}
    </section>
  );
}

export const query: BlockQuery<void> = async (
  props,
  story,
  locale,
  isWorkingInStoryblok
) => {
  const stories = await getAllStoryblokStories({
    starts_with: "journal/",
    is_startpage: false,
    sort_by: "sort_by_date:desc",
    version: isWorkingInStoryblok ? "draft" : "published",
  });

  return {
    stories,
  };
};
