import React, { useState } from "react";
import { default as NextImage } from "next/image";
import classNames from "classnames";
// Import the CSS module

function loader({ src, width }) {
  if (src.indexOf("a.storyblok.com") < 0) {
    return src;
  }

  const url = new URL(src);
  url.hostname = "img2.storyblok.com";
  url.pathname = `${width}x0${url.pathname}`;

  return url.toString();
}

type Props = {
  className?: string;
  fill?: boolean;
  sizes?: string;
  src?: string;
  alt?: string;
  asset?: StoryblokAsset;
  mobile?: StoryblokAsset;
} & ({ asset: StoryblokAsset } | { src: string });

export default function StoryblokImage({
  className,
  fill = false,
  sizes,
  src,
  alt,
  asset,
  mobile,
}: Props): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);

  src = src || asset?.filename;
  alt = alt || asset?.alt;

  if (!src) {
    return <></>;
  }

  const mobileSrc = mobile?.filename;
  const mobileAlt = mobile?.alt;

  const width = src.split("/")[5].split("x")[0];
  const height = src.split("/")[5].split("x")[1];

  const mobileWidth = mobileSrc ? mobileSrc?.split("/")[5].split("x")[0] : null;
  const mobileHeight = mobileSrc ? mobileSrc?.split("/")[5].split("x")[1] : null; 

  const mainImage = width && height ? (
    <NextImage
      className={classNames(className, {
        "is-loaded": isLoaded,
      })}
      src={src}
      alt={alt}
      layout={fill ? "fill" : "responsive"}
      width={fill ? null : width}
      height={fill ? null : height}
      objectFit={fill ? "cover" : null}
      loader={loader}
      sizes={sizes || "100vw"}
      onLoad={() => {
        setTimeout(() => setIsLoaded(true), 0);
      }}
    />
  ) : (
    <img className={classNames(className)} src={src} alt={alt} style={{ display: mobileSrc ? 'none' : 'block' }} />
  );

  const mobileImage = mobileSrc ? (
    mobileWidth && mobileHeight ? (
      <NextImage
        className={classNames(className, 'Image-mobile', {
          "is-loaded": isLoaded,
        })}
        src={mobileSrc}
        alt={mobileAlt}
        layout={fill ? "fill" : "responsive"}
        width={fill ? null : mobileWidth}
        height={fill ? null : mobileHeight}
        objectFit={fill ? "cover" : null}
        loader={loader}
        sizes={sizes || "100vw"}
        onLoad={() => {
          setTimeout(() => setIsLoaded(true), 0);
        }}
      />
    ) : (
      <img className={classNames(className, 'Image-mobile')} src={mobileSrc} alt={mobileAlt} style={{ display: 'block' }} /> 
    )
  ) : null;

  return (
    <>
      {mobileImage}
      {mainImage}
    </>
  );  
}
